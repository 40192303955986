<template>
  <RecipeEditView disabled />
</template>

<script>
import RecipeEditView from '@/views/recipe/RecipeEditView'

export default {
  name: 'RecipeView',
  components: {
    RecipeEditView
  }
}
</script>
